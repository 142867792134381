/* eslint-disable no-trailing-spaces */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import {
    DTO, DTORef, DTOMetaData, DTOMethods, DTOClassMethods,
    BoundEndpointAPI} from '../dto';
import { ObservabilityAllocation_v1 } from './observability-allocation-v1';

export interface DynatraceEnvironment_v1 extends DTO, DTOMethods<DynatraceEnvironment_v1> {
    uuid: string;
    url: string;
    isProduction: boolean;
    apiToken: string;
    allocTotalHosts: number;
    allocTotalHUs: number;
    allocTotalappSec: number;
    allocTotalsesMon: number;
    allocTotallogMon: number;
    allocTotaldduMon: number;
    allocTotaldemMon: number;
    cluster: DTORef[];
    allocations: ObservabilityAllocation_v1[];
    claims: DTORef[];
}
export const DynatraceEnvironment_v1: DTOMetaData & DTOClassMethods<DynatraceEnvironment_v1> = {
    _label:'Dynatrace Environments',
    _dto: 'DynatraceEnvironment_v1',
    _type: 'dynatrace-environment',
    _colors: { primaryThemeColor: "#4a89d2", secondaryThemeColor: "#446c9b", tertiaryThemeColor: "#446c9b" },
    _keysets: ["id"], // TODO: Generate from DTO Schema
    _properties: [
        { label: 'IDX (External Identifier)', prop: 'idx', hint: 'ID of this environment', type: 'string', fixed: true, source: null, values: undefined },
        { label: 'UUID', prop: 'uuid', hint: 'Text (max 36 chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'URL', prop: 'url', hint: 'Text (max 512 chars)', type: 'string', fixed: false, source: undefined, values: undefined },
        { label: 'Has production', required: true, prop: 'isProduction', hint: 'Yes/No', type: 'boolean', fixed: true, source: undefined, values: undefined },
        // { label: 'Alloc total hosts',  prop: 'allocTotalHosts',  hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined },
        // { label: 'Alloc total h us',   prop: 'allocTotalHUs',    hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined },
        // { label: 'Alloc totalapp sec', prop: 'allocTotalappSec', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined },
        // { label: 'Alloc totalses mon', prop: 'allocTotalsesMon', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined },
        // { label: 'Alloc totallog mon', prop: 'allocTotallogMon', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined },
        // { label: 'Alloc totalddu mon', prop: 'allocTotaldduMon', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined },
        // { label: 'Alloc totaldem mon', prop: 'allocTotaldemMon', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined },
     ],
    _related: [
        { prop: 'cluster', type: 'dynatrace-cluster', class: 'DynatraceCluster_v1', label: 'Dynatrace Clusters' },
        { prop: 'allocations', type: 'observability-allocation', class: 'ObservabilityAllocation_v1', label: 'Observability Allocation' },
        { prop: 'claims', type: 'observability-claim', class: 'ObservabilityClaim_v1', label: 'Claims' },
     ],
    _endpoint: '/api/eob/v1.0/dynatrace-environment/v1.0',
    _parentDTONames: 'DynatraceCluster_v1',
    _docLink: '/wiki/elevate/Assets/dynatarce-environment/',
    _icon: './assets/dtos/dynatrace-environment.svg',

    endpointAPI: new BoundEndpointAPI<DynatraceEnvironment_v1>('DynatraceEnvironment_v1', DTO),
    from: (obj: any): DynatraceEnvironment_v1 => {
        return Object.setPrototypeOf(obj, DynatraceEnvironment_v1._prototype);
    },
    select: (p: any = ''): any => {
        return DynatraceEnvironment_v1.endpointAPI.get(p).then(res =>
            !res ? undefined
            : Array.isArray(res) ? res.map(a => DynatraceEnvironment_v1.from(a) as DynatraceEnvironment_v1)
            : DynatraceEnvironment_v1.from(res)
    )}
}
